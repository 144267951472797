.react-datepicker {
  position: absolute;
  z-index: 10;
  display: flex;
}
.react-datepicker__tab-loop {
  z-index: 30;
}
.react-datepicker > .react-datepicker__month-container:nth-child(5) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.react-datepicker > .react-datepicker__month-container:nth-child(6) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.react-datepicker__header {
  background-color: #2f3d44;
}
.react-datepicker__month-container {
  background-color: #2f3d44;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__current-month {
  color: white;
  background-color: #2f3d44;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__day--in-range {
  background-color: rgba(255, 255, 255, 0.2);
}
.react-datepicker__day:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.react-datepicker__close-icon::after {
  background-color: transparent;
  font-size: 24px;
}
.react-datepicker__close-icon:hover {
  background-color: rgba(255, 255, 255, 0.3);
  padding-left: 4px;
}
@media screen and (max-width: 550px) {
  .react-datepicker__day, .react-datepicker__day-name{
    padding: 0;
    margin: 1px;
    width: 25px;
    height: 25px;
  }
  .react-datepicker__month, .react-datepicker__day-names, .react-datepicker__month-container {
    width: 210px;
  }
}
