.SmartInstructions {
  background: linear-gradient(to bottom, white, #F2F2F2);
}
.bg_block {
  clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0);
  border-radius: 70px;
}

@media (max-width: 1250px) {
  .second {
    margin-right: 30px;
  }
  .third {
    margin-left: 30px;
  }
  .fourth {
    margin-right: 90px;
  }
}
@media (max-width: 1150px) {
  .second {
    margin-right: 20px;
  }
  .third {
    margin-left: 20px;
  }
  .fourth {
    margin-right: 30px;
  }
}
@media (max-width: 1070px) {
  .first .instruction_image {
    max-width: 260px;
  }
  .second {
    margin-top: -190px;
  }
  .second p {
    margin-right: 150px;
  }
  .second .instruction_image {
    max-width: 280px;
  }
  .third .instruction_image {
    max-width: 260px;
  }
  .fourth .instruction_image {
    max-width: 240px;
    margin-top: -260px;
    margin-left: -90px;
  }
}
@media (max-width: 940px) {
  .bg_block {
    clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0 0);
  }
  .first p, .second p, .third p, .fourth p {
    font-size: 24px;
    line-height: 26px;
  }
  .first .instruction_image {
    margin-left: -80px;
  }
  .second {
    margin-top: -190px;
  }
  .second p {
    margin-right: 50px;
  }
  .second .instruction_image {
    margin-right: -60px;
  }
  .third {
    margin-left: 0;
  }
  .third .instruction_image {
    max-width: 240px;
  }
  .fourth {
    margin-right: -30px;
  }
  .fourth .instruction_image {
    max-width: 220px;
    margin-top: -230px;
    margin-left: -85px;
  }
  .instruction_end {
    max-width: 565px;
    margin-top: -380px;
  }
}
@media (max-width: 768px) {
  .bg_block {
    clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
  }
  .bg_block {
    clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
    border-radius: 50px;
  }
  .first, .second, .third, .fourth {
    margin: 0;
    margin-top: 90px;
  }
  .first {
    margin-top: 20px;
  }
  .second {
    margin-top: 140px;
  }
  .first p, .second p, .third p, .fourth p {
    margin: 0;
    font-size: 22px;
    line-height: 26px;
  }
}
@media (max-width: 510px) {
  .bg_block {
    padding-bottom: 350px;
  }
  .instruction_end {
    margin-top: -300px;
  }
}
@media (max-width: 425px) {
  .bg_block {
    clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
    padding-bottom: 320px;
  }
  .instruction_end {
    margin-top: -200px;
  }
  .first p, .second p, .third p, .fourth p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
  }
  .message_block {
    font-size: 16px;
    line-height: 24px;
  }
  .robo {
    margin-left: -50px;
  }
  .indicate {
    max-width: 170px;
  }
}