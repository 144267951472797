.Slide {
  box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.10);
}
@media (max-width: 1380px) {
  .Slide {
    padding: 27px;
  }
  .box_map {
    max-width: 300px;
  }
  .box_image {
    max-width: 140px;
  }
  .slide_text h2 {
    font-size: 24px;
  }
  .slide_text p {
    font-size: 16px;
  }
}
@media (max-width: 1240px) {
  .card_text {
    margin-left: -28px;
  }
  .box_map {
    max-width: 250px;
  }
  .box_image {
    max-width: 130px;
  }
  .slide_text h2 {
    font-size: 22px;
  }
  .slide_text p {
    font-size: 14px;
  }
}
@media (max-width: 1100px) {
  .box_map {
    max-width: 230px;
  }
  .box_image {
    max-width: 120px;
  }
}
@media (max-width: 1024px) {
  .Slide {
    padding: 20px;
    padding-top: 28px;
    border-radius: 15px;
  }
  .card_text {
    margin-left: -23px;
  }
  .box_map {
    max-width: none;
    height: 250px;
  }
  .box_image {
    max-width: 130px;
  }
  .slide_text {
    margin-top: 13px;
    margin-bottom: 15px;
  }
  .slide_text h2 {
    font-size: 28px;
  }
  .slide_text p {
    font-size: 18px;
  }
}
@media (max-width: 730px) {
  .Slide {
    padding: 15px;
    padding-top: 28px;
  }
  .box_map {
    height: 200px;
  }
}
@media (max-width: 640px) {
  .Slide {
    padding: 12px;
    padding-top: 28px;
  }
  .box_image {
    max-width: 110px;
  }
}
@media (max-width: 500px) {
  .card_text {
    margin-left: 0;
  }
  .box_map {
    height: 120px;
  }
  .slide_text h2 {
    font-size: 20px;
  }
  .slide_text p {
    font-size: 14px;
  }
}