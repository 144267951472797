@media (max-width: 1100px) {
  .boxes_info_container {
    margin-bottom: -240px;
  }
  .order_btn {
    margin-top: 12%;
  }
  .order_btn a {
    font-size: 20px;
    padding: 0 25px;
    padding-top: 18px;
    padding-bottom: 20px;
  }
}
@media (max-width: 1035px) {
  .order_btn {
    margin-top: 10%;
  }
}
@media (max-width: 1000px) {
  .boxes_info_container {
    margin-bottom: -220px;
  }
  .order_btn {
    margin-top: 8%;
  }
  .order_btn a {
    font-size: 18px;
    padding: 0 22px;
    padding-top: 16px;
    padding-bottom: 18px;
  }
}
@media (max-width: 900px) {
  .boxes_info_container {
    margin-bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .box {
    max-width: 320px;
    width: 100%;
    margin-bottom: 65px;
  }
  .box:last-child {
    margin-bottom: 120px;
  }
  .box_info {
    padding: 36px 32px 36px 32px;
  }
  .order_btn {
    margin-top: 12%;
  }
  .order_btn a {
    font-size: 24px;
    padding: 0 30px;
    padding-top: 20px;
    padding-bottom: 22px;
  }
}
@media (max-width: 500px) {
  .box {
    max-width: 250px;
  }
  .box h2 {
    font-size: 20px;
  }
  .box p {
    font-size: 14px;
  }
  .box_info {
    padding: 26px 28px 26px 28px;
  }
  .order_btn {
    margin-top: 10%;
  }
  .order_btn a {
    font-size: 20px;
    padding: 0 25px;
    padding-top: 14px;
    padding-bottom: 16px;
  }
}

@media (max-width: 505px) {
  .title {
    max-width: 240px;
  }
}


@media (max-width: 400px) {
  .title h2 {
    font-size: 24px;
  }
}

@media (max-width: 340px) {
  .order_btn {
    margin-top: 8%;
  }
  .box_info {
    padding: 20px 23px 20px 23px;
  }
}