.cell_table_container::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

.cell_table_container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.cell_table_container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.cell_table_container::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}