.Drawer .content {
  background-color: rgba(0, 0, 0, 0.75);
  width: 260px;
  overflow: hidden;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 25px;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;

  transform: translateX(-300px);
  transition: transform .3s;
}
.Drawer .backdrop {
  background-color: rgba(0, 0, 0, .3);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
  display: none;
}

.open .backdrop {
  display: block;
}
.open .content {
  transform: translateX(0);
}

@media (min-width: 915px) {
  .Drawer .backdrop {
    display: none;
  }
  .Drawer .content {
    transform: translateX(-300px);
  }
} 