.Partners {
  background-color: white;
  overflow: hidden;
  clip-path: polygon(50% 15%, 100% 0, 100% 100%, 0 100%, 0 0);
}

.partners_btn {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media (max-width: 900px) {
  .Partners {
    clip-path: polygon(50% 8%, 100% 0, 100% 100%, 0 100%, 0 0);
    padding-top: 200px;
  }
}

@media (max-width: 425px) {
  .Partners {
    padding-top: 160px;
  }
  .partners_title {
    font-size: 20px;
  }
  .partners_text {
    max-width: 200px;
  }
  .partners_text p {
    font-size: 14px;
  }
  .partners_btn {
    font-size: 22px;
  }
}