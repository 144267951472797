@media (min-width: 915px) {
  .nav_item {
    width: auto;
    background-color: transparent;
    padding: 0;
    font-size: 16px;
    margin-top: 0;
  }
  .nav_item a {
    color: #1C0448;
  }
}