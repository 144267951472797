.footer_toolbar {
  max-width: 200px;
}

.footer_nav li {
  margin-bottom: 17px;
}
.footer_nav li:last-child {
  margin-bottom: 0;
}

.copyright_text {
  transform: translateX(-48px);
}

@media (max-width: 900px) {
  .footer_toolbar {
    max-width: 100%;
  }
  .copyright_text {
    transform: translateX(0);
    order: 2;
    margin-top: 40px;
  }
  .footer_contacts {
    order: 1;
    margin-top: 85px;
  }
  .footer_section {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
  }
}