@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,400;8..144,500;8..144,600;8..144,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  background-color: #f3f3f3;
}

#root {
  height: 100%;
}

.font-roboto {
  font-family: 'Roboto Flex', sans-serif;
}
.font-manrope {
  font-family: 'Manrope', sans-serif;
}

#SmartSwiper {
  overflow: visible;
}