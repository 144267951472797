@media (min-width: 915px) {
  .nav_toggle {
    display: none;
  }
}
@media (max-width: 420px) {
  .nav_toggle {
    width: 35px;
  }
}
