@media (max-width: 1040px) {
  .logo {
    margin-right: 10px;
  }
}
@media (min-width: 915px) {
  .user_nav {
    justify-content: flex-start;
  }
}
@media (max-width: 420px) {
  .user_nav {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .robo {
    width: 24px;
    height: 24px;
  }
  .logo {
    width: 60px;
  }
}