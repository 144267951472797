@media (max-width: 430px) {
  .first_paragraph {
    max-width: 290px;
  }
}
@media (max-width: 370px) {
  .logo {
    max-width: 140px;
  }
  .text_block {
    font-size: 14px;
  }
}