@media (max-width: 1350px) {
  .arrow_down {
    margin-top: 27%;
  }
}
@media (max-width: 1255px) {
  .header_text_block h2 {
    font-size: 38px;
  }
  .header_text_block img {
    max-width: 260px;
  }
  .arrow_down {
    margin-top: 25%;
  }
}
@media (max-width: 1150px) {
  .header_text_block h2 {
    font-size: 33px;
  }
  .header_text_block img {
    max-width: 240px;
  }
  .arrow_down {
    margin-top: 20%;
  }
}
@media (max-width: 1005px) {
  .header_text_block h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .header_text_block img {
    max-width: 220px;
  }
}
@media (max-width: 925px) {
  .header_text_block h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .header_text_block img {
    max-width: 210px;
  }
}
@media (max-width: 870px) {
  .header_text_block h2 {
    font-size: 26px;
  }
  .header_text_block img {
    max-width: 200px;
  }
}
@media (max-width: 815px) {
  .header_text_block h2 {
    font-size: 24px;
  }
  .header_text_block img {
    max-width: 190px;
  }
}
@media (max-width: 767px) {
  .header_text_block h2 {
    font-size: 32px;
  }
  .header_text_block img {
    max-width: 230px;
  }
  .arrow_down {
    margin-top: 30%;
  }
}
@media (max-width: 420px) {
  .header_text_block h2 {
    font-size: 22px;
    line-height: 25px;
    max-width: 280px;
  }
  .header_text_block img {
    max-width: 170px;
  }
  .arrow_down {
    margin-top: 30%;
  }
}